var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.showingAllItems || !(_vm.numberOfPages < 1),
          expression: "!showingAllItems || !(numberOfPages < 1)",
        },
      ],
      staticClass: "pagination",
    },
    [
      _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canGoToPreviousSet,
              expression: "canGoToPreviousSet",
            },
          ],
          staticClass: "btn btn--primary btn--sm",
          on: {
            click: function ($event) {
              return _vm.previousSet()
            },
          },
        },
        [_vm._v("PREV " + _vm._s(_vm.NUMBER_OF_PAGES_PER_SHIFT))]
      ),
      _vm.shouldShowPrevDots
        ? [
            _c("li", { staticClass: "pagination--counter" }, [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.changePage(0, 1)
                    },
                  },
                },
                [_vm._v(_vm._s(1))]
              ),
            ]),
            _c("li", { staticClass: "pagination--counter" }, [_vm._v("...")]),
          ]
        : _vm._e(),
      _vm._l(_vm.pagesToSee, function (page, index) {
        return _c(
          "li",
          {
            key: page,
            staticClass: "pagination--counter",
            class: { "current-page": page == _vm.pageIndex },
          },
          [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    return _vm.changePage(index * _vm.itemsPerPage, page)
                  },
                },
              },
              [_vm._v(_vm._s(page))]
            ),
          ]
        )
      }),
      _vm.shouldShowNextDots
        ? [
            _c("li", { staticClass: "pagination--counter" }, [_vm._v("...")]),
            _c("li", { staticClass: "pagination--counter" }, [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      _vm.changePage(
                        (_vm.numberOfPages - 1) * _vm.itemsPerPage,
                        _vm.numberOfPages
                      )
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.numberOfPages))]
              ),
            ]),
          ]
        : _vm._e(),
      _c("div", [
        _vm.canGoToNextSet
          ? _c(
              "a",
              {
                staticClass: "btn btn--primary btn--sm",
                on: {
                  click: function ($event) {
                    return _vm.nextSet()
                  },
                },
              },
              [_vm._v("NEXT " + _vm._s(_vm.NUMBER_OF_PAGES_PER_SHIFT))]
            )
          : _vm._e(),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  !_vm.IS_ADMIN && _vm.isForBeats && !(_vm.numberOfPages < 1),
                expression: "!IS_ADMIN && isForBeats && !(numberOfPages < 1)",
              },
            ],
            staticClass: "btn btn--primary btn--sm",
            on: { click: _vm.fetchAllItems },
          },
          [_vm._v("SHOW ALL")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }